<template>
  <div class="dataAnalysis-container">
    <div class="dataAnalysis-header">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/admin/dataAnalysis/manager' }">数据分析管理</el-breadcrumb-item>
        <el-breadcrumb-item>数据管理</el-breadcrumb-item>
      </el-breadcrumb>
    <div>
      <el-button class="btn-blue" @click="sendData">发送</el-button>
      <el-button class="btn-blue" @click="addBtn">添加</el-button>
    </div>
    
    </div>
    <el-table ref="multipleTable" :data="ListData" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium" :header-cell-style="{ fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA' }" :cell-style="{ fontSize: '14px', color: '#343441' }">
      <el-table-column type="selection" label="全选" width="45"></el-table-column>
      <el-table-column prop="name" label="数据名称" align="left"></el-table-column>
      <el-table-column label="操作" align="center" width="300px">
        <template slot-scope="scope">
          <el-link :underline="false" style="margin-right: 9px" class="link-blue" @click="copy(scope.row)">复制</el-link>
          <el-link :underline="false" class="link-blue" @click="editBtn(scope.row)">编辑</el-link>
          <el-link style="margin-left: 10px" :underline="false" type="danger" @click="delBtn(scope.row.id)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pager-center" style="margin-top: 10px" :current-page="listPages.currentPageNum" :page-size="listPages.eachPageNum" :total="listPages.total" layout="prev, pager, next, jumper" @current-change="CurrentChange"> </el-pagination>
  </div>
</template>

<script>
import { getAnalysisListData, delAnalysisData, copyDataManager,dataAnalysisSend } from "@/utils/apis";
export default {
  data() {
    return {
      ListData: [],
      listPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
    };
  },
  mounted() {
    this.getListData();
  },
  methods: {
    getListData() {
      let params = {
        paging: 1,
        page: this.listPages.currentPageNum,
        pageSize: this.listPages.eachPageNum,
      };
      getAnalysisListData(params).then((res) => {
        this.ListData = res.data.list;
        this.listPages.total = res.data.total;
      });
    },
    addBtn() {
      this.$router.push("/admin/dataAnalysis/create");
    },
    async sendData(){
      let id =this.$refs.multipleTable.selection.map((item) => item.id);
      if(id && id.length > 0){
        const result = await this.$common.sendData(dataAnalysisSend, { ids: id });
        result && this.getListData();
      }else{
        this.$message.error("请勾选需要发送的数据")
      }
    },
    copy(item) {
      this.$confirm("是否复制该条数据", "提示消息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btn-blue",
        type: "warning",
      })
        .then(() => {
          copyDataManager({ id: item.id })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("复制成功");
                this.getListData();
              }else{
                this.$message.error(res.msg);
              }
            })
        })
        .catch(() => {
          this.$message.info("已取消复制");
        });
    },
    editBtn(item) {
      this.$router.push({
        path: "/admin/dataAnalysis/create",
        query: {
          id: item.id,
        },
      });
    },
    delBtn(item) {
      this.$confirm("是否删除该条数据，删除完将无法恢复", "提示消息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btn-blue",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: item,
          };
          delAnalysisData(params)
            .then((res) => {
              if(res.code===200){
                this.$message.success(res.msg);
                this.getListData();
              }else{
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err);
            });
        })
        .catch(() => {
          this.$message.info("已取消删除");
        });
    },
    CurrentChange(val) {
      this.listPages.currentPageNum = val;
      this.getListData();
    },
  },
};
</script>

<style lang="scss" scoped>
.dataAnalysis-container {
  padding: 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .dataAnalysis-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-breadcrumb {
      line-height: 40px;
    }
  }
}
</style>
